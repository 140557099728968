import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import cocktails from '../assets/images/menu/Cocktails.png';
import dessert from '../assets/images/menu/Dessert.png';
import food1 from '../assets/images/menu/Food Menu.png';
import salad from '../assets/images/menu/Salad 1.png';
import pasta1 from '../assets/images/menu/Pasta 1.png';
import pasta2 from '../assets/images/menu/Pasta 2.png';
import tapas1 from '../assets/images/menu/Tapas 1.png';
import tapas2 from '../assets/images/menu/Tapas 2.png';
import tapas3 from '../assets/images/menu/Tapas 3.png';
import drink1 from '../assets/images/menu/Drink Menu.png';
import drink2 from '../assets/images/menu/Drinks 1.png';

const menuItems = [
  { category: 'Food', name: 'Food Item 1', image: food1 },
  { category: 'Food', name: 'Food Item 2', image: pasta1 },
  { category: 'Food', name: 'Food Item 3', image: pasta2 },
  { category: 'Food', name: 'Food Item 4', image: salad },
  { category: 'Food', name: 'Food Item 6', image: tapas1 },
  { category: 'Food', name: 'Food Item 7', image: tapas2 },
  { category: 'Food', name: 'Food Item 8', image: tapas3 },
  { category: 'Food', name: 'Food Item 9', image: dessert },
  { category: 'Drinks', name: 'Drink Item 1', image: drink1 },
  { category: 'Drinks', name: 'Drink Item 2', image: drink2 },
  { category: 'Drinks', name: 'Cocktails', image: cocktails },
];

const Menu: React.FC = () => {
  return (
    <Box sx={{ bgcolor: '#f5f5f5', pb: 5 }}> 
      <Container sx={{ my: 5 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#3e2723' }}>Our Menu</Typography>
        
        <Box sx={{ my: 3 }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#A52A2A' }}>Food</Typography>
          <Grid container spacing={4}>
            {menuItems
              .filter(item => item.category === 'Food')
              .map((item, index) => (
                <Grid 
                  item 
                  xs={12}  // Full width on small screens
                  sm={12}  // 1 column on small screens
                  md={6}   // 2 columns on medium to large screens
                  key={index}
                >
                  <Box
                    sx={{
                      width: '100%',
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      style={{ 
                        maxWidth: '100%', 
                        height: 'auto'
                      }}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#A52A2A' }}>Drinks</Typography>
          <Grid container spacing={4}>
            {menuItems
              .filter(item => item.category === 'Drinks')
              .map((item, index) => (
                <Grid 
                  item 
                  xs={12}  // Full width on small screens
                  sm={12}  // 1 column on small screens
                  md={6}   // 2 columns on medium to large screens
                  key={index}
                >
                  <Box
                    sx={{
                      width: '100%',
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      style={{ 
                        maxWidth: '100%', 
                        height: 'auto'
                      }}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Menu;