import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button, Grid } from '@mui/material';
import { Facebook, Instagram, Phone } from '@mui/icons-material';  // Import Phone icon
import { request } from '../_libs/api';

const Contact: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await request('/webform/contact-us', {
      name,
      email,
      message,
    })
      .then(() => {
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        console.error('CONTACT-US validation error', error, error.subError);
      });
  };

  return (
    <Container 
      sx={{ 
        my: 5, 
        bgcolor: '#ffffff',  // Set background color to white for better contrast
        p: 4,  // Add padding for a cleaner look
        borderRadius: 2,  // Rounded corners
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',  // Add a subtle shadow for depth
        maxWidth: '800px', // Limit max width for readability
        width: '100%', // Ensure it takes full width on smaller screens
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#3e2723' }}>
        Contact Us
      </Typography>
      
      {/* Social Media and Phone Section */}
      <Box 
        sx={{ 
          display: 'flex', 
          flexWrap: 'wrap', // Allow items to wrap on small screens
          alignItems: 'center', 
          mb: 4, 
          justifyContent: 'space-between' // Space out elements on small screens
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mr: 2 }}>
          <Facebook sx={{ color: '#3b5998', mr: 1 }} />
          <Typography variant="body1">
            <a 
              href="https://www.facebook.com/share/5NJP6rJo4Hb2WBHq/?mibextid=LQQJ4d" 
              target="_blank" 
              rel="noopener noreferrer" 
              style={{ textDecoration: 'none', color: '#3b5998' }}
            >
              facebook.com/share/5NJP6rJo4Hb2WBHq
            </a>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mr: 2 }}>
          <Instagram sx={{ color: '#E4405F', mr: 1 }} />
          <Typography variant="body1">
            <a 
              href="https://www.instagram.com/sorsi.n.morsipattaya?igsh=MXF6cTV4MTVucTRxaQ==" 
              target="_blank" 
              rel="noopener noreferrer" 
              style={{ textDecoration: 'none', color: '#E4405F' }}
            >
              instagram.com/sorsi.n.morsipattaya
            </a>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Phone sx={{ color: '#3e2723', mr: 1 }} />
          <Typography variant="body1">
            <a href="tel:+66923137517" style={{ textDecoration: 'none', color: '#3e2723' }}>
              092 313 7517
            </a>
          </Typography>
        </Box>
      </Box>

      {/* Contact Form */}
      <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              id="name"
              label="Name"
              variant="outlined"
              sx={{ bgcolor: '#fff' }}  // Ensure form inputs have a background color
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              id="email"
              label="Email"
              variant="outlined"
              sx={{ bgcolor: '#fff' }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="message"
              label="Message"
              variant="outlined"
              multiline
              rows={4}
              sx={{ bgcolor: '#fff' }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button 
          variant="contained" 
          sx={{ 
            mt: 3, 
            bgcolor: '#A52A2A', 
            color: '#fff', 
            width: '100%', // Make button full-width on small screens
            '&:hover': { bgcolor: '#7f1f1f' } 
          }} 
          type="submit"
        >
          Send Message
        </Button>
      </Box>
    </Container>
  );
};

export default Contact;
