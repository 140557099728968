import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { aboutUs } from '../content'; // Import content from content.ts
import { GoogleMap, useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api'; 

const containerStyle = {
  width: '100%',
  height: '300px'
};

// Add the Place ID
const placeId = "ChIJtcApTNGXAjERAuypERYTYvA"; // Replace with your place's actual Place ID

const AboutUs: React.FC = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '', // Make sure your API key is in .env
    libraries: ['places'] // Load the Places library
  });

  const [center, setCenter] = useState<{ lat: number; lng: number } | null>(null);

  useEffect(() => {
    if (isLoaded && window.google) {
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));

      // Fetch details using the Place ID
      service.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && place?.geometry?.location) {
          // Set the center of the map to the place location
          setCenter({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          });
        } else {
          console.error('Failed to fetch place details:', status);
        }
      });
    }
  }, [isLoaded]);

  return (
    <Box sx={{ bgcolor: '#f5f5f5', pb: 5 }}> {/* Light grey background */}
      <Container sx={{ my: 5 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#3e2723' }}>About Us</Typography>
        
        {/* Section 1: Chef Rio */}
        <Box sx={{ my: 3 }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#4B3621' }}>
            {aboutUs[0].par1Title}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" sx={{ color: '#4B3621', textAlign: 'justify' }}>
                {aboutUs[0].par1Text}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: '350px',
                  height: '476px',
                  overflow: 'hidden',
                  borderRadius: '8px',
                  mx: 'auto'
                }}
              >
                <img
                  src={aboutUs[0].par1Image} // Use image from content.ts
                  alt="Chef Valerio (Rio)"
                  style={{ 
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Section 2: Our Philosophy */}
        <Box sx={{ my: 3 }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#4B3621' }}>
            {aboutUs[0].par2Title}
          </Typography>
          <Typography variant="body1" sx={{ color: '#4B3621', mb: 2, textAlign: 'justify' }}>
            {aboutUs[0].par2Text}
          </Typography>
        </Box>

        {/* Section 3: Our Location */}
        <Box sx={{ my: 3 }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#4B3621' }}>
            {aboutUs[0].par3Title}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" sx={{ color: '#4B3621', textAlign: 'justify' }}>
                {aboutUs[0].par3Text}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoaded && center ? (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}  // Set the center to the fetched place location
                  zoom={17} // Zoom in
                >
                  {/* Optional: Add a marker */}
                </GoogleMap>
              ) : (
                <Typography>Loading map...</Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Footer Section */}
      <Box sx={{ bgcolor: '#3e2723', color: '#fff', py: 3 }}>
        <Container>
          <Typography variant="body2" align="center">© 2024 Sorsi & Morsi. All Rights Reserved.</Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default AboutUs;
