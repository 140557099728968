import React from 'react';
import { Container, Typography, Card, CardContent, CardActions, Button } from '@mui/material';

const Dashboard: React.FC = () => {
  return (
    <Container sx={{ my: 5 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#3e2723' }}>
        Dashboard
      </Typography>
      <Typography variant="body1" sx={{ color: '#4B3621', mb: 4 }}>
        Welcome to your dashboard!
      </Typography>
      <Card sx={{ maxWidth: 345, bgcolor: '#f5f5f5', color: '#3e2723' }}> {/* Light grey background with Chocolate text */}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Go to SomoApp
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Click the button below to visit SomoApp.
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" href="https://somoapp.netlify.app" target="_blank">
            Visit SomoApp
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};

export default Dashboard;
