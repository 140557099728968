// Import all images at the top
//Images of Featured Dishes
import plin from './assets/images/featured_dishes/PlinPasta.png';
import lobsterbisque from './assets/images/featured_dishes/TagliatelleLobsterBisque.png';
import liverpate from './assets/images/featured_dishes/LiverPate.png';
import tunatartare from './assets/images/featured_dishes/TunaTartare.png';

//Images of Carousel
import assorted from './assets/images/carousel/Homepage Carousel/online/Assorted1000x667.jpg';
import bolognese from './assets/images/carousel/Homepage Carousel/online/Bolognese1000x667.jpg';
import cheecks from './assets/images/carousel/Homepage Carousel/online/CheeckMeatballs1000x667.jpg';
import pepper from './assets/images/carousel/Homepage Carousel/online/Pepper1000x667.jpg';
import picasso from './assets/images/carousel/Homepage Carousel/online/Picasso1000x750.png';
import picassoFront from './assets/images/carousel/Homepage Carousel/online/PicassoFront1000x750.jpg';
import pizze from './assets/images/carousel/Homepage Carousel/online/Pizze1000x667.jpg';
import plins from './assets/images/carousel/Homepage Carousel/online/Plins1000x667.jpg';
import prosecco from './assets/images/carousel/Homepage Carousel/online/Prosecco1000x667.jpg';
import radicchio from './assets/images/carousel/Homepage Carousel/online/Radicchio1000x667.jpg';
import table from './assets/images/carousel/Homepage Carousel/online/Table1000x667s.png';
import windows from './assets/images/carousel/Homepage Carousel/online/windows1000x750.jpg';


import rio from './assets/images/people/Chef Rio.png';

// Featured Dishes
const featuredDishes = [
  {
    name: 'Plin Pasta',
    description: `Our Plin Pasta is made with delicate, homemade egg pasta, filled with tender stewed pork and
                  aromatic herbs. Served on a rich onion cream sauce and topped with perfectly seasoned Pecorino
                  cheese, this dish is the ultimate balance of flavors. A personal favorite of Chef Rio—don’t miss out
                  on this culinary masterpiece!`,
    image: plin,  // Adjust image paths if necessary
  },
  {
    name: 'Tagliatelle Lobster Bisque',
    description: `Indulge in our Tagliatelle Lobster Bisque, featuring homemade egg pasta and fresh lobster stewed
                  for hours to create a rich, flavorful bisque. This dish offers the essence of the sea in every bite, with
                  a luxurious depth of flavor. A must-try for anyone looking for an unforgettable pasta experience!`,
    image: lobsterbisque,
  },
  {
    name: 'Liver Pâté',
    description: `Our Liver Pâté is a delicate, melt-in-your-mouth treat. Served on toasted bread and whipped into an
                  airy, fluffy mousse, it’s paired with candied caramelized onions for a sublime contrast of flavors. This
                  dish promises an explosion of taste in every bite—a harmonious blend of richness and sweetness
                  that’s simply irresistible.`,
    image: liverpate,
  },
  // {
  //   name: 'Tuna Tartare',
  //   description: `A personal favorite, our Tuna Tartare features fresh, locally sourced bluefin tuna served on a bed of
  //                 homemade tzatziki and topped with seasoned homemade cheese. Light, refreshing, and bursting
  //                 with flavor, it’s the perfect start to any meal. The combination of the fresh tuna, creamy yogurt, and
  //                 cheese creates a unique and delicious harmony of flavors.`,
  //   image: tunatartare,
  // },
];

export default featuredDishes;

// Homepage Content
const homepageContent = {
  mission: {
    title: 'A Culinary Journey Like No Other',
    text: `Welcome to Sorsi & Morsi, where Italian cuisine is reimagined with a fresh, exciting twist! Led by
                  internationally renowned Master Chef Rio, we’re not just serving food; we’re creating an extraordinary
                  culinary journey that goes beyond taste. Chef Rio masterfully combines traditional Italian flavors with
                  innovative techniques to deliver an experience that is both exciting and sensual for your taste buds.
                  Everything at Sorsi & Morsi is crafted in-house, fresh every day—from our handmade pasta to our vibrant
                  sauces and pizza dough. We’re committed to using locally sourced products, ensuring every dish not only
                  supports local producers but also delivers top-tier quality.
                  We believe that everyone should have access to this extraordinary experience, which is why we keep our
                  prices accessible without compromising on quality. At Sorsi & Morsi, you’ll find a dining adventure that’s
                  as affordable as it is unique.
                  As the first of its kind in Pattaya, we’re bringing something truly new to the city. We invite you to join us
                  for an unforgettable dining experience, where every meal is a journey down the path of bold flavors and
                  culinary artistry.
                  Sorsi & Morsi—the one and only in Pattaya, where tradition meets innovation.`,
  },
};

export { homepageContent };

export const homepageHero = {
  title: 'THE BEST CASUAL FINE DINING IN PATTAYA',
  subtitle: 'ITALIAN TAPAS PASTA & PIZZA',
  buttonText: 'BOOK A TABLE',
};

// Carousel Images
export const carouselImages = [
  {
    src: assorted,
    alt: 'Restaurant Picture',
  },
  {
    src: bolognese,
    alt: 'Restaurant Picture',
  },
  {
    src: cheecks,
    alt: 'Restaurant Picture',
  },
  {
    src: pepper,
    alt: 'Restaurant Picture',
  },
  {
    src: picasso,
    alt: 'Restaurant Picture',
  },
  {
    src: picassoFront,
    alt: 'Restaurant Picture',
  },
  {
    src: pizze,
    alt: 'Restaurant Picture',
  },
  {
    src: plins,
    alt: 'Restaurant Picture',
  },
  {
    src: prosecco,
    alt: 'Restaurant Picture',
  },
  {
    src: radicchio,
    alt: 'Restaurant Picture',
  },
  {
    src: table,
    alt: 'Restaurant Picture',
  },
  {
    src: windows,
    alt: 'Restaurant Picture',
  },
];

//About Us page
export const aboutUs = [
  {
    par1Title: 'Meet Chef Rio',
    par1Text: `Chef Rio’s culinary journey began in the heart of his home kitchen at the young age of 12, where his passion for food was first ignited. 
          By 16, he was already cooking professionally, driven by a relentless curiosity to explore new flavors and techniques. Now, with over 12 years of 
          experience across various types of hospitality businesses, Chef Rio has honed his craft to perfection. A graduate in Culinary and Art Science, 
          Chef Rio has had the privilege of working alongside some of the nation’s top chefs, sharpening his skills and fueling his passion for innovation. 
          An expert in developing new concepts, Chef Rio combines traditional culinary techniques with a modern twist, always aiming to deliver an unforgettable dining experience. 
          With a deep knowledge of diverse cuisines—from Latin American and Japanese to Thai and Mediterranean—Chef Rio’s kitchen is a melting pot of global flavors. 
          Whether it's handcrafted pizzas, artisanal bakery items, or cutting-edge dishes, he is committed to expanding people’s horizons about food. Above all, 
          Chef Rio strives to offer the best value for money, ensuring that every guest at Sorsi & Morsi experiences high-quality, innovative cuisine without the premium price tag. 
          For Chef Rio, food is more than sustenance—it’s an experience, a journey of discovery, and he invites you to join him at Sorsi & Morsi to embark 
          on a culinary adventure like no other.`,
    par1Image: rio,

    par2Title: 'Our Philosophy',
    par2Text: `At Sorsi & Morsi, we believe food should not only be fresh but also a 
                meaningful connection to the local community. That’s why we embrace 
                the zero kilometres philosophy—sourcing nearly all of our ingredients 
                from local farmers and vendors. Every dish is crafted using farm-fresh, 
                never-frozen, and packaging-free products, allowing us to deliver 
                unparalleled quality and flavour while supporting the local economy. 
                Our mission is to break the mold of fine dining by bringing the premium 
                quality of high-end European cuisine to a more accessible, casual dining 
                experience. At Sorsi & Morsi, we are passionate about making every 
                meal an extraordinary journey of flavours—without the premium price 
                tag. Our innovative take on Italian cuisine, led by Master Chef Rio, 
                ensures every bite is a fusion of creativity, authenticity, and excitement.` ,

    par3Title: 'Our Location',
    par3Text: `Located just 8 minutes from Jomtien Beach in Pattaya, Sorsi & Morsi 
                invites you to discover modern Italian cuisine like never before. Join us 
                and experience a dining adventure that’s both unforgettable and 
                approachable! `,
  }
]
