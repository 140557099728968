import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import users from '../users.json';
import { useAuth } from '../AuthContext';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault();
    const user = users.find(u => u.username === username && u.password === password);
    if (user) {
      setError('');
      login();
      navigate('/');
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <Container 
      sx={{ 
        my: 5, 
        bgcolor: '#ffffff',  // Set background color to white for contrast
        p: 4,  // Add padding
        borderRadius: 2,  // Rounded corners
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',  // Subtle shadow for better visibility
        maxWidth: '400px'  // Limit form width for better design
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#3e2723', textAlign: 'center' }}> {/* Chocolate */}
        Login
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <Box component="form" onSubmit={handleLogin} noValidate autoComplete="off">
        <TextField
          fullWidth
          required
          id="username"
          label="Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ mb: 2, bgcolor: '#fff' }}  // Ensure input has white background
        />
        <TextField
          fullWidth
          required
          id="password"
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mb: 2, bgcolor: '#fff' }}  // Ensure input has white background
        />
        <Button 
          variant="contained" 
          sx={{ mt: 3, bgcolor: '#A52A2A', color: '#fff', '&:hover': { bgcolor: '#7f1f1f' } }} 
          type="submit"
        > {/* Dark Red with hover effect */}
          Login
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
