import React, { useState } from 'react';
import { Container, Typography, Box, Button, Grid, Snackbar, Alert } from '@mui/material';
import Slider from 'react-slick';
import FeaturedDishes from '../components/FeaturedDishes'; 
import { homepageContent } from '../content';  // Import content
import { homepageHero } from '../content';  // Import hero content
import { carouselImages } from '../content';  // Import centralized carousel images

import Navigation from '../components/Navigation';  // Import Navigation component
import BookingModal from '../components/BookingModal';

const Home: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Faster autoplay speed
  };
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ bgcolor: '#f5f5f5' }}> {/* Light grey background */}

      {/* Hero Section */}
      <Box sx={{ position: 'relative', height: '70vh', width: '100%' }}>
        <Box
          sx={{
            position: 'absolute',
            top: '0',   // Stick to the top of the container
            left: '0',
            width: '100%',  // Full width
            zIndex: 2,  // Ensure the navigation stays above everything
          }}
        >
          <Navigation /> {/* Your existing navigation component */}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '20%',
            left: '50%',
            transform: 'translate(-50%, 0)',
            textAlign: 'center',
            zIndex: 1,  // Ensures text is on top of images
            color: '#FFFFFF',
            textShadow: '2px 2px 4px #3e2723'
          }}
        >
          <Typography 
            variant="h2" 
            component="h1" 
            sx={{ 
              fontSize: '2rem', 
              fontWeight: 'bold', 
              textShadow: '2px 2px 4px #3e2723'  // Chocolate border around text
            }}
          >
            {homepageHero.title}
          </Typography>
          <Typography 
            variant="h5" 
            component="p" 
            sx={{ 
              fontSize: '1rem', 
              textShadow: '2px 2px 4px #3e2723'  // Chocolate border around text
            }}
          >
            {homepageHero.subtitle}
          </Typography>

        </Box>

        {/* Button Positioned at the Bottom */}
        <Box
          sx={{
            position: 'absolute',
            bottom: '5%',  // Position button near the bottom
            left: '50%',
            transform: 'translateX(-50%)',  // Center button horizontally
            zIndex: 1,
          }}
        >
          <BookingModal /> {/* The booking button is now a modal */}
        </Box>

        {/* Carousel Section */}
        <Slider {...settings}>
          {carouselImages.map((image, index) => (
            <Box key={index}
              sx={{
                height: '70vh', 
                width: '100%', 
                backgroundImage: `url(${image.src})`,  // Dynamically load image from centralized content
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#fff',
                textAlign: 'center',
                p: 3
      }}
    >

    </Box>
  ))}
</Slider>
      </Box>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
          The "Book a Table" section is not ready yet. We are working hard to make it available soon. Thank you for your understanding!
        </Alert>
      </Snackbar>

      {/* About Us Section */}
      <Container sx={{ my: 10 }}> {/* Increased margin */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#3e2723' }}> {/* Chocolate */}
              {homepageContent.mission.title}
            </Typography>
            <Typography variant="body1" sx={{ color: '#4B3621', textAlign: 'justify' }}>
              {homepageContent.mission.text}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#3e2723' }}> {/* Chocolate */}
              Customer Ratings
            </Typography>
            <Typography variant="body1" sx={{ color: '#4B3621' }}> {/* Darker Chocolate */}
              ★★★★☆ (4.5/5 based on 15 reviews)
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {/* Featured Dishes Section */}
      <FeaturedDishes /> 

      {/* Footer Section */}
      <Box sx={{ bgcolor: '#3e2723', color: '#fff', py: 3 }}> {/* Chocolate */}
        <Container>
          <Typography variant="body2" align="center">© 2024 Sorsi & Morsi. All Rights Reserved.</Typography>
        </Container>
      </Box>
    </Box>
  );
}

export default Home;


