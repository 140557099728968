import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Button, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../AuthContext';  // Assuming you're using AuthContext for login state

const Navigation: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { loggedIn, logout } = useAuth();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', bgcolor: 'transparent', py: 1 }}>
      {!isSmallScreen ? (
        <Box sx={{ display: 'flex', gap: 2 }}>
          {/* Updated Buttons */}
          <Button 
            sx={{ 
              color: '#D4AF37', 
              // bgcolor: '#8B0000',  // Muted dark red
              textShadow: '2px 2px 4px #3e2723', 
              fontSize: '1.1rem',
              padding: '10px 20px',
              borderRadius: '5px',
              '&:hover': { bgcolor: '#A52A2A' },  // Darker red on hover
              fontWeight: 'bold'
            }} 
            component={NavLink} 
            to="/"
          >
            Home
          </Button>
          <Button 
            sx={{ 
              color: '#D4AF37', 
              // bgcolor: '#8B0000',  // Muted dark red
              textShadow: '2px 2px 4px #3e2723', 
              fontSize: '1.1rem',
              padding: '10px 20px',
              borderRadius: '5px',
              '&:hover': { bgcolor: '#A52A2A' },
              fontWeight: 'bold'
            }} 
            component={NavLink} 
            to="/menu"
          >
            Menu
          </Button>
          <Button 
            sx={{ 
              color: '#D4AF37', 
              // bgcolor: '#8B0000',
              textShadow: '2px 2px 4px #3e2723', 
              fontSize: '1.1rem',
              padding: '10px 20px',
              borderRadius: '5px',
              '&:hover': { bgcolor: '#A52A2A' },
              fontWeight: 'bold'
            }} 
            component={NavLink} 
            to="/about"
          >
            About Us
          </Button>
          <Button 
            sx={{ 
              color: '#D4AF37', 
              // bgcolor: '#8B0000',
              textShadow: '2px 2px 4px #3e2723', 
              fontSize: '1.1rem',
              padding: '10px 20px',
              borderRadius: '5px',
              '&:hover': { bgcolor: '#A52A2A' },
              fontWeight: 'bold'
            }} 
            component={NavLink} 
            to="/contact"
          >
            Contact
          </Button>

          {/* Logged-in user options */}
          {loggedIn ? (
            <>
              <Button sx={{ color: '#D4AF37', 
                // bgcolor: '#8B0000', 
                textShadow: '2px 2px 4px #3e2723', 
                fontSize: '1.1rem', padding: '10px 20px', borderRadius: '5px', fontWeight: 'bold', '&:hover': { bgcolor: '#A52A2A' } }} component={NavLink} to="/dashboard">Dashboard</Button>
              <Button sx={{ color: '#D4AF37', 
               textShadow: '2px 2px 4px #3e2723', 
                // bgcolor: '#8B0000', 
                fontSize: '1.1rem', padding: '10px 20px', borderRadius: '5px', fontWeight: 'bold', '&:hover': { bgcolor: '#A52A2A' } }} onClick={logout}>Logout</Button>
            </>
          ) : (
            <Button sx={{ color: '#D4AF37',
              textShadow: '2px 2px 4px #3e2723', 
              //  bgcolor: '#8B0000', 
               fontSize: '1.1rem', padding: '10px 20px', borderRadius: '5px', fontWeight: 'bold', '&:hover': { bgcolor: '#A52A2A' } }} component={NavLink} to="/login">Login</Button>
          )}
        </Box>
      ) : (
        <>
          {/* Drawer for smaller screens */}
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon sx={{ color: '#D4AF37' }} />
          </IconButton>
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            <List onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
              <ListItem button component={NavLink} to="/">
                <ListItemText primary="Home" sx={{ color: '#3e2723' }} />
              </ListItem>
              <ListItem button component={NavLink} to="/menu">
                <ListItemText primary="Menu" sx={{ color: '#3e2723' }} />
              </ListItem>
              <ListItem button component={NavLink} to="/about">
                <ListItemText primary="About Us" sx={{ color: '#3e2723' }} />
              </ListItem>
              <ListItem button component={NavLink} to="/contact">
                <ListItemText primary="Contact" sx={{ color: '#3e2723' }} />
              </ListItem>
              {loggedIn ? (
                <>
                  <ListItem button component={NavLink} to="/dashboard">
                    <ListItemText primary="Dashboard" sx={{ color: '#3e2723' }} />
                  </ListItem>
                  <ListItem button onClick={logout}>
                    <ListItemText primary="Logout" sx={{ color: '#3e2723' }} />
                  </ListItem>
                </>
              ) : (
                <ListItem button component={NavLink} to="/login">
                  <ListItemText primary="Login" sx={{ color: '#3e2723' }} />
                </ListItem>
              )}
            </List>
          </Drawer>
        </>
      )}
    </Box>
  );
}

export default Navigation;


