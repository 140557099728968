import React from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import featuredDishes from '../content';  // Import the centralized content with image paths

const FeaturedDishes: React.FC = () => {
  return (
    <Container sx={{ my: 5 }}>
      <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#3e2723' }}>
        Featured Dishes
      </Typography>
      <Grid container spacing={4}>
        {featuredDishes.map((dish, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ bgcolor: '#f5f5f5', color: '#3e2723', textAlign: 'justify' }}>
              {/* Use the image path directly from the content file */}
              <CardMedia
                component="img"
                height="200"
                image={dish.image}  // No comment directly inside the attribute
                alt={dish.name}
              />
              <CardContent>
                <Typography variant="h5" component="h3">
                  {dish.name}
                </Typography>
                <Typography variant="body2">
                  {dish.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default FeaturedDishes;


