import React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField } from '@mui/material';

interface DateTimePickerProps {
  label: string;
  value: Date | null;
  onChange: (newValue: Date | null) => void;
}

const DateTimePickerComponent: React.FC<DateTimePickerProps> = ({ label, value, onChange }) => {
  return (
    <DateTimePicker
      label={label}
      value={value}
      onChange={onChange}
      disablePast
      renderInput={(params) => <TextField {...params} fullWidth />}
    />
  );
};

export default DateTimePickerComponent;
