import React, { useState } from 'react';
import DateTimePickerComponent from '../components/DateTimePickerComponent';
import { Box, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const TestDateTimePicker = () => {
  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: '#f5f5f5', // Light gray background color
          borderRadius: 2, // Rounded corners
          boxShadow: 3, // Slight shadow for better visibility
          maxWidth: 600, // Set a maximum width
          margin: 'auto', // Center the box horizontally
          mt: 4, // Add some margin on top for spacing
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>Test Date and Time Picker</Typography>

        <DateTimePickerComponent
          label="Select Date and Time"
          value={selectedDateTime}
          onChange={(newValue) => setSelectedDateTime(newValue)}
        />

        <Typography variant="h6" sx={{ mt: 3 }}>
          Selected Date and Time: {selectedDateTime ? selectedDateTime.toString() : 'None'}
        </Typography>
      </Box>
    </LocalizationProvider>
  );
};

export default TestDateTimePicker;

