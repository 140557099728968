import React from 'react';
import './App.css';  // Ensure this is present
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import Header from './components/Header'; 
import Home from './pages/Home';
import Menu from './pages/Menu';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Layout from './layout/Layout';  // Import the Layout component
import background from './assets/images/Floor_Lines.jpg';  // Import the background image
import BookNow from './pages/BookNow';
import TestDateTimePicker from './tests/TestDateTimePicker'; // Import the test page
import { Helmet } from 'react-helmet';

const App: React.FC = () => {
  return (
    <div
      style={{
        position: 'relative',
        minHeight: '100vh',  // Ensures the background covers the full viewport
      }}
    >
      {/* Adding the Google Analytics script with Helmet */}
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-W5S3926XE0"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-W5S3926XE0');
          `}
        </script>
      </Helmet>
      
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,  // Ensure the background stays behind the content
        }}
      />
      <Router>
        <Header />  {/* Keep this if Header is required globally */}
        <Container>
          <Routes>
            {/* Home page without the layout */}
            <Route path="/" element={<Home />} />

            {/* Pages wrapped with the Layout component */}
            <Route
              path="/menu"
              element={
                <Layout>
                  <Menu />
                </Layout>
              }
            />
            <Route
              path="/about"
              element={
                <Layout>
                  <AboutUs />
                </Layout>
              }
            />
            <Route
              path="/contact"
              element={
                <Layout>
                  <Contact />
                </Layout>
              }
            />

            {/* Login and Dashboard functionality */}
            <Route
              path="/login"
              element={
                <Layout>
                  <Login />
                </Layout>
              }
            />
            <Route
              path="/dashboard"
              element={
                <Layout>
                  <Dashboard />
                </Layout>
              }
            />
            <Route
              path="/book-now"
              element={
                <Layout>
                  <BookNow />
                </Layout>
              }
            />
            <Route
              path="/test-datetime-picker"
              element={
                <Layout>
                  <TestDateTimePicker  />
                </Layout>
              }
            />

          </Routes>
        </Container>
      </Router>
    </div>
  );
};

export default App;
