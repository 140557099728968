import React from 'react';
import { Box, IconButton, Container } from '@mui/material';
import logo from '../assets/images/Logo/SM-Logo-150x100.png';  
import headerImage from '../assets/images/HeaderTextureWood.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const facebookIcon = faFacebook as IconProp;
const instagramIcon = faInstagram as IconProp;

const Header: React.FC = () => {
  return (
    <Box 
      sx={{ 
        height: '100px',  
        width: '100%',  
        backgroundImage: `url(${headerImage})`,  
        backgroundSize: 'cover',  
        backgroundPosition: 'center',  
        backgroundRepeat: 'no-repeat',  
        position: 'relative',  
        bgcolor: '#3e2723',  
        py: 2,
        px: 3  
      }}
    >
      {/* Align content within the app margin */}
      <Container
        maxWidth="lg"  // Align with the main app's content width
        sx={{
          display: 'flex',
          justifyContent: 'space-between',  // Ensure space between icons and logo
          alignItems: 'center',
          height: '100%',
        }}
      >
        {/* Social Icons */}
        <Box>
          <IconButton
            component="a"
            href="https://www.facebook.com/share/5NJP6rJo4Hb2WBHq/?mibextid=LQQJ4d"
            sx={{ color: '#D4AF37', mx: 1 }}  
            target="_blank"  
          >
            <FontAwesomeIcon icon={facebookIcon} size="lg" />
          </IconButton>

          <IconButton
            component="a"
            href="https://www.instagram.com/sorsi.n.morsipattaya?igsh=MXF6cTV4MTVucTRxaQ==" 
            sx={{ color: '#D4AF37', mx: 1 }}  
            target="_blank"  
          >
            <FontAwesomeIcon icon={instagramIcon} size="lg" />
          </IconButton>
        </Box>

        {/* Logo Centered */}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt="Sorsi & Morsi" width="150" height="100" />
        </Box>

        {/* Empty box to balance the layout */}
        <Box sx={{ display: 'flex', width: '150px' }} /> {/* Adjust width to match logo size */}
      </Container>
    </Box>
  );
}

export default Header;







