import React, { ReactNode } from 'react';
import Navigation from '../components/Navigation'  // Adjust path as needed

type LayoutProps = {
    children: ReactNode;
  };

  const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Navigation />
      <main>{children}</main> {/* This will render the content of the page */}
    </>
  );
};

export default Layout;
